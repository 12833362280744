@import 'style';

.choice {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;

	&-box {
		margin-bottom: -10px;
	}

	&-image {
		width: 100%;
		max-width: 450px;
		height: auto;
		position: relative;
		z-index: 10;
		cursor: pointer;

		&.is-finished {
			cursor: auto;
		}
	}

	&-label {
		margin: 2px 0 20px 0;
		text-align: center;
		font-size: 18px;
		font-weight: bold;
		color: $secondary;
		text-transform: uppercase;
		position: relative;
		cursor: pointer;

		&.is-finished {
			cursor: auto;
		}
	}

	&-text {
		width: 100%;
		padding: 12px;
		background-color: $primary;
		z-index: 10;
		cursor: pointer;

		&.is-checked {
			background-color: $secondary;
		}

		&.is-finished {
			cursor: auto;
		}
	}
}
