@import 'include-media/dist/_include-media.scss';

$primary: #39b4e8;
$secondary: #006fb7;
$white: #ffffff;
$light-grey: #d3d3d3;

$breakpoints: (
	tablet: 600px,
	ipad-port: 768px,
	ipad-land: 1024px,
	desktop: 1200px
);

$tablet: '>=tablet';
$ipad-port: '>=ipad-port';
$ipad-land: '>=ipad-land';
$desktop: '>=desktop';

@mixin ie-only() {
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		@content;
	}
}
