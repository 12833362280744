@import 'style';

.layout {
	display: flex;
	flex-direction: column;

	&-content {
		flex: 1;
	}
}
