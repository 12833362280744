@import 'style';

.timer {
	margin-top: 40px;
	text-align: center;
	color: $primary;
	font-size: 23px;
	font-weight: bold;

	&-image {
		display: inline;
		margin: 0 7px;
	}
}

.question {
	margin: 15px 0;
	text-align: center;
	font-size: 18px;
	color: $secondary;
	font-weight: 300;
	text-transform: uppercase;

	&-question {
		font-weight: bold;
	}
}

.answer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 10px 0;
	width: 100%;
	height: 100%;
	position: relative;
	text-align: center;
	color: $white;

	&-input {
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		cursor: inherit;
		margin: 0;
		opacity: 0;
		position: absolute;
	}
}
