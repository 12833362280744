@import 'style';

.checkbox {
	width: 25px;
	height: 25px;
	background-color: $white;
	border: solid $primary 3px;
	position: relative;
	z-index: 20;
	cursor: pointer;

	&-checkmark {
		top: -9px;
		left: 2px;
		width: 22px;
		height: auto;
		position: relative;
		opacity: 0;

		&.is-checked {
			opacity: 1;
		}
	}
}
