@import 'style';

.room {
	margin-top: 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	color: $primary;
	font-weight: bold;
	text-transform: uppercase;

	&-score {
		&-title {
			font-size: 18px;
		}

		&-score {
			font-size: 39px;
		}
	}

	&-text {
		margin-top: 49px;
		font-size: 15px;
	}
}

.status {
	position: relative;
	padding-top: 40px;
	text-align: left;
	font-size: 12px;

	&-title {
		position: relative;
		margin-bottom: 10px;
	}

	&-room {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding-bottom: 5px;
		font-size: 11px;

		&-label {
			padding-left: 10px;
		}
	}
}
