@import 'style';

.image {
	position: relative;
	width: 100%;
	max-width: 450px;
	height: auto;
}

.answer {
	padding: 40px 0 0 0;
	text-align: center;
	font-size: 18px;
	color: $secondary;

	&-number {
		text-transform: uppercase;
		font-weight: 300;
	}

	&-answer {
		font-weight: bold;
		font-size: 39px;
		color: $primary;
		text-transform: uppercase;
	}

	&-score {
		font-weight: bold;
		font-size: 20px;
		color: $primary;
		text-transform: uppercase;
	}

	&-button {
		margin: 8px 0;
		padding: 10px;
		width: 100%;
		color: $white;
		font-size: 15px;
		font-weight: bold;
		text-transform: uppercase;
		border: none;
		background-color: $primary;
		cursor: pointer;
	}

	&-correct {
		position: relative;
		font-size: 14px;
		padding: 20px 0;

		&-answer {
			position: relative;
			color: $white;
		}
	}
}
