@import 'style';

.button {
	margin: 8px 0;
	padding: 13px;
	width: 100%;
	color: $white;
	font-size: 15px;
	font-weight: bold;
	text-transform: uppercase;
	border: none;
	background-color: $primary;
	cursor: pointer;

	&:disabled {
		background-color: $light-grey;
		cursor: inherit;
	}
}
