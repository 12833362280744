@import 'style';

.container {
	width: 100%;
	max-width: 800px;
	margin: 0 auto;

	&.mod-normal {
		padding: 0 28px;
	}

	&.mod-large {
		padding: 0;
	}

	&.mod-small {
		padding: 0 44px;
	}
}
