@import 'style';

.room {
	margin: 40px 0;
	text-align: center;
	font-size: 18px;
	color: $secondary;
	font-weight: 300;
	text-transform: uppercase;

	&-description {
		margin-bottom: 15px;
		text-transform: none;
	}
}
