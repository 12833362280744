@import 'style';

.quiz {
	margin-top: 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	color: $primary;
	font-weight: bold;
	text-transform: uppercase;

	&-score {
		&-title {
			font-size: 18px;
		}

		&-score {
			font-size: 39px;
		}
	}

	&-text {
		margin: 49px 0;
		font-size: 15px;
	}

	&-link {
		display: block;
		padding: 14px;
		background-color: $primary;
		color: $white;
		font-size: 15px;
		word-break: break-word;
	}

	&-label {
		display: block;
		padding: 10px 0;
	}
}
